.home_container {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

.home__map {
    flex: 1;
}

.home__dev_info {
    width: 500px;
}