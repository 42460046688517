.header_container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 60px;

    background-color: #161b20;
}

.header_container > img {
    height: 45px;

    margin: 0px 0px 0px 10px;
    border-radius: 5px;

    background-color: white;
}

.header_container > p {
    margin-left: 20px;
    margin-right: 20px;

    color: #dcdcdd;
    text-align: center;
}

.header_container > p:first-child {
    font-weight: bolder;
}

.header__spacer {
    flex: 1;
}

.header__button {
    display: flex;

    height: 100%;
    width: 60px;
}

button {
    flex: 1;
    margin: 4px;

    border: none;
    border-radius: 3px;

    background-color: #39404b;
    color: #dcdcdd;
    outline: none;
    font-size: 10px;

    cursor: pointer;
}

button:hover {
    background-color: #202730;
}