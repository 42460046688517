.device_info_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background-color: #21272f;
}

.device_info__basic {
    display: flex;
    flex-direction: column;

    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;

    color: #a6acb9;
}

.device_info__basic__title {
    margin: 25px 0px 25px 0px;

    color: #62b354;
    font-weight: bolder;
    font-size: 20px;
}

.device_info__basic__entry {
    display: flex;
    flex-direction: row;

    align-items: center;

    height: 30px;

    font-size: 14px;
}

.device_info__basic__entry > div:first-child {
    width: 100px;
}

.device_info__basic__entry > div:last-child {
    flex: 1;
}

.device_info__tab {
    width: 100%;
    height: 100%;
}

.tab_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.tab_container__header {
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;
    height: 30px;
}

.tab_container__header > div {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
    width: 100%;

    font-size: 14px;

    color: #a6acb9;
}

.tab_container__header > div > p {
    flex: 1;

    text-align: center;
    cursor: pointer;
    font-weight: bolder;
}

.tab_container__item--select {
    background-color: #37404c;
}

.tab_container__item:hover {
    background-color: #37404c;
}

.tab_container__content {
    height: 100%;

    background-color: #37404c;
    overflow-y: scroll;
}