.distance_container {
    color: white;

    margin: 10px;

    max-height: 500px;
}

.distance__chart {
    display: flex;
    flex-direction: column;

    height: 250px;
    font-size: 12px;
}

.distance__chart > :last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.distance__chart > p {
    margin: 15px 5px 5px 5px;
    font-size: 13px;
    font-weight: bolder;
}

.distance__chart > div {
    flex: 1;

    background-color: #21272f;
    border-radius: 7px;
}