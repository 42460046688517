@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
body {
  display: flex;
  flex: 1 1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header_container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 60px;

    background-color: #161b20;
}

.header_container > img {
    height: 45px;

    margin: 0px 0px 0px 10px;
    border-radius: 5px;

    background-color: white;
}

.header_container > p {
    margin-left: 20px;
    margin-right: 20px;

    color: #dcdcdd;
    text-align: center;
}

.header_container > p:first-child {
    font-weight: bolder;
}

.header__spacer {
    flex: 1 1;
}

.header__button {
    display: flex;

    height: 100%;
    width: 60px;
}

button {
    flex: 1 1;
    margin: 4px;

    border: none;
    border-radius: 3px;

    background-color: #39404b;
    color: #dcdcdd;
    outline: none;
    font-size: 10px;

    cursor: pointer;
}

button:hover {
    background-color: #202730;
}

.distance_container {
    color: white;

    margin: 10px;

    max-height: 500px;
}

.distance__chart {
    display: flex;
    flex-direction: column;

    height: 250px;
    font-size: 12px;
}

.distance__chart > :last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.distance__chart > p {
    margin: 15px 5px 5px 5px;
    font-size: 13px;
    font-weight: bolder;
}

.distance__chart > div {
    flex: 1 1;

    background-color: #21272f;
    border-radius: 7px;
}
.acc_container {
    color: white;

    max-height: 500px;
}

.acc__tension {
    margin: 10px;
}

.acc__tension > p {
    font-size: 13px;
    font-weight: bolder;
}

.acc__tension > div {
    background-color: #21272f;
    border-radius: 7px;
}

.acc__lists {
    margin: 0px 20px 0px 20px;

    overflow-y: auto;
}

.acc__entity {
    border-bottom: 1px solid #a6acb9;
}

.acc__entity__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 40px;
}

.acc__entity__title--select {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 40px;
}

.acc__entity__title__name {
    flex: 1 1;
    margin: 0px 20px 0px 20px;

    font-size: 14px;
}

.acc__entity__title:hover {
    background-color: #21272f;
}

.acc__entity__chart_container {
    background-color: #21272f;
    border-radius: 5px;
}

.acc__entity__chart {
    display: flex;
    flex-direction: column;

    margin: 0px;
    padding: 0px;
}

.acc__entity__chart > p {
    margin: 5px 10px 5px 10px;
    font-size: 14px;
}

.acc__lists__page {
    display: flex;
    flex-direction: row;;
    align-items: center;
}

.acc__lists__page > div {
    flex: 1 1;
    text-align: center;
    font-size: 15px;

    cursor: pointer;
}
.device_info_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background-color: #21272f;
}

.device_info__basic {
    display: flex;
    flex-direction: column;

    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;

    color: #a6acb9;
}

.device_info__basic__title {
    margin: 25px 0px 25px 0px;

    color: #62b354;
    font-weight: bolder;
    font-size: 20px;
}

.device_info__basic__entry {
    display: flex;
    flex-direction: row;

    align-items: center;

    height: 30px;

    font-size: 14px;
}

.device_info__basic__entry > div:first-child {
    width: 100px;
}

.device_info__basic__entry > div:last-child {
    flex: 1 1;
}

.device_info__tab {
    width: 100%;
    height: 100%;
}

.tab_container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.tab_container__header {
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;
    height: 30px;
}

.tab_container__header > div {
    flex: 1 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    height: 100%;
    width: 100%;

    font-size: 14px;

    color: #a6acb9;
}

.tab_container__header > div > p {
    flex: 1 1;

    text-align: center;
    cursor: pointer;
    font-weight: bolder;
}

.tab_container__item--select {
    background-color: #37404c;
}

.tab_container__item:hover {
    background-color: #37404c;
}

.tab_container__content {
    height: 100%;

    background-color: #37404c;
    overflow-y: scroll;
}
.home_container {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

.home__map {
    flex: 1 1;
}

.home__dev_info {
    width: 500px;
}
@-webkit-keyframes spinner {
    to {transform: rotate(360deg);}
}
   
@keyframes spinner {
    to {transform: rotate(360deg);}
}
   
.spinner {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    -webkit-animation: spinner .6s linear infinite;
            animation: spinner .6s linear infinite;
}
#root {
  width: 100%;
  height: 100%;
}
.app_container {
    font-family: "Noto Sans KR";

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}
