.acc_container {
    color: white;

    max-height: 500px;
}

.acc__tension {
    margin: 10px;
}

.acc__tension > p {
    font-size: 13px;
    font-weight: bolder;
}

.acc__tension > div {
    background-color: #21272f;
    border-radius: 7px;
}

.acc__lists {
    margin: 0px 20px 0px 20px;

    overflow-y: auto;
}

.acc__entity {
    border-bottom: 1px solid #a6acb9;
}

.acc__entity__title {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 40px;
}

.acc__entity__title--select {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 40px;
}

.acc__entity__title__name {
    flex: 1;
    margin: 0px 20px 0px 20px;

    font-size: 14px;
}

.acc__entity__title:hover {
    background-color: #21272f;
}

.acc__entity__chart_container {
    background-color: #21272f;
    border-radius: 5px;
}

.acc__entity__chart {
    display: flex;
    flex-direction: column;

    margin: 0px;
    padding: 0px;
}

.acc__entity__chart > p {
    margin: 5px 10px 5px 10px;
    font-size: 14px;
}

.acc__lists__page {
    display: flex;
    flex-direction: row;;
    align-items: center;
}

.acc__lists__page > div {
    flex: 1;
    text-align: center;
    font-size: 15px;

    cursor: pointer;
}