@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

#root {
  width: 100%;
  height: 100%;
}
.app_container {
    font-family: "Noto Sans KR";

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}